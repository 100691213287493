<template>
  <div class="bg p20 con">
    <div class="banner dfc">
      <div class="ban dfc b1">
        <div class="text">
          <div class="tit">AI创作</div>
          <div class="desc">向AI提问，一键生成视频方案</div>
          <div class="btn" @click="navTo('text')">点击创作</div>
        </div>
        <img src="~@/assets/img/b1.png" alt="" />
      </div>
      <div class="ban dfc b2">
        <div class="text">
          <div class="tit">AI绘画</div>
          <div class="desc">Ai智能绘画，所想即所见</div>
          <div class="btn" @click="navTo('painting')">去绘画</div>
        </div>
        <img src="~@/assets/img/b2.png" alt="" />
      </div>
      <div class="ban dfc b3">
        <div class="text">
          <div class="tit">数字人克隆</div>
          <div class="desc">拍几分钟视频，即可克隆数字人</div>
          <!-- <div class="btn" @click="clone">克隆形象</div>
          <div class="btn" @click="clone">克隆声音</div> -->

          <el-button-group class="mt20">
            <el-button type="primary" class="cloneBtn" v-if="userInfo.calculations == 1 || userInfo.calculations == 3" @click="clone(1)">克隆形象</el-button>
            <!-- <el-button type="success" class="cloneBtn" v-if="userInfo.calculations == 2 || userInfo.calculations == 3" @click="clone(2)">克隆高阶形象</el-button> -->
            <el-button type="warning" class="cloneBtn" @click="clone(3)">克隆声音</el-button>
          </el-button-group>
        </div>

        <img src="~@/assets/img/b3.png" alt="" />
      </div>
    </div>

    <div class="bg-white df jc-sbn head br8">
      <div v-for="(i, idx) in catList" :key="i.id" class="cat" :class="idx == cur ? 'act' : ''" @click="change(i.id, idx)">{{ i.cat_name }}</div>
    </div>
    <div v-if="videoList.length > 0" class="df f-w box">
      <div v-for="(item, idx) in videoList" :key="idx" class="imgBox" @click="getItem(item)">
        <img :src="item.images" object-fit="cover" alt="" />
        <div class="itools">使用该形象</div>
      </div>
    </div>
    <div v-else>
      <myempty text="视频"></myempty>
    </div>
  </div>
</template>
<script>
import { get_list, get_cat } from "@/api/user.js";
import myempty from "@/components/myempty.vue";
export default {
  components: {
    myempty,
  },
  // name: "home",
  data() {
    return {
      cur: 0,
      id: "",
      videoList: [],
      catList: [],
      userInfo: {},
    };
  },
  mounted() {
    this.userInfo = localStorage.getItem("userInfo") ? JSON.parse(localStorage.getItem("userInfo")) : {};

    this.get_cat();
  },
  methods: {
    clone(type) {
      // this.$notify({
      //   title: "克隆形象",
      //   message: "请联系客服添加克隆形象",
      // });
      if (type == 3) {
        this.$router.push({ name: "audioClone" });
      } else {
        this.$router.push({ name: "clone" });
      }
    },
    navTo(i) {
      this.$router.push({ name: i });
    },
    getItem(item) {
      this.$router.push({ name: "shortVideo", params: { imageId: item.image_id } });
    },
    change(i, idx) {
      this.cur = idx;
      this.id = i;
      this.getlist();
    },

    getlist() {
      get_list({ cat_id: this.id }).then((res) => {
        console.log(res);
        if (res.code == "200") {
          this.videoList = res.data.list;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    get_cat() {
      get_cat().then((res) => {
        console.log(res);
        if (res.code == "200") {
          this.catList = res.data;
          this.id = this.catList[0].id;
          this.getlist();
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
::v-deep {
  .el-button {
  }
}

.con {
  height: 917px;
  overflow: hidden;
}
.banner {
  margin-bottom: 20px;
  .ban {
    width: 520px;
    height: 230px;
    padding: 40px 10px 30px 40px;
    border-radius: 8px;
    background: url(~@/assets/img/bannerBg.png) no-repeat;
    background-size: cover;

    img {
      width: 200px;
      height: 200px;
    }

    .text {
      width: 270px;
      text-align: left;

      .tit {
        color: #002fff;
        font-size: 32px;
        font-weight: 800;
        letter-spacing: 0px;
        line-height: 43.2px;
      }
      .desc {
        color: #0070f0;
        font-size: 19px;
      }

      .btn {
        margin-top: 20px;
        color: #fff;
        width: 110px;
        height: 45px;
        line-height: 45px;
        border-radius: 6px;
        text-align: center;
        background: #015ce6;
      }

      .cloneBtn {
        height: 45px;
        border-radius: 8px;
        margin-left: 5px;
      }
    }
  }
}

.cat {
  margin-right: 10px;
}

.act {
  color: #015ce6;
}

.box {
  min-height: 574px;
  height: 574px;
  width: 101%;
}

.imgBox {
  margin-right: 15px !important;
  height: 336px;

  .itools {
    margin-top: -30px;
    color: #015ce6;
    height: 30px;
    line-height: 30px;
    background-color: rgba(255, 255, 255, 0.6);
    font-weight: 500;
    backdrop-filter: blur(6px);
  }
}
</style>
